<template>
  <v-app-bar
    elevation="0"
    class="main-header"
    height="64"
    fixed
    color="primary"
    dark
  >
    <v-btn icon class="mx-1" @click.stop="TOGGLE_DRAWER">
      <template v-if="DRAWER_STATE">
        <v-icon size="28" :class="$vuetify.theme.dark ? 'primary--text' : null"
          >mdi-window-close</v-icon
        >
      </template>
      <template v-else>
        <v-icon size="28" :class="$vuetify.theme.dark ? 'primary--text' : null"
          >mdi-menu</v-icon
        >
      </template>
    </v-btn>
    <v-toolbar-title class="pr-1" :class="$vuetify.theme.dark ? 'primary--text' : null"
      >Signal<i>View</i></v-toolbar-title
    >

    <v-spacer></v-spacer>

    <div class="greeting-text mr-3 d-none d-md-block">
      Hi,
      <span >{{
        usernameFormatted
      }}</span>
    </div>
    <v-menu 
      ref="menu"
      min-width="180" 
      offset-y 
      bottom 
      left 
      nudge-bottom="10" 
      :eager="true"
      @input="setMenuLocationStyle()"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="40"
          v-bind="attrs"
          v-on="on"
          :class="{ 'd-none': !avatar }"
        >
          <img :src="avatar" :alt="user.name" />
        </v-avatar>
        <div
          :class="{ 'd-none': avatar, 'd-flex': !avatar }"
          style="width: 40px; height: 40px; border-radius: 50%"
          class="
            deep-orange
            lighten-5
            mr-2
            justify-center
            align-center
            primary--text
          "
          v-bind="attrs"
          v-on="on"
        >
          {{ firstUserLetter }}
        </div>
      </template>
      <v-list ref="list">
        <div class="d-flex justify-center my-3">
          <v-btn
            width="80%"
            large
            outlined
            color="primary"
            class="text-capitalize"
            @click="logoutUser"
            >Sign Out</v-btn
          >
        </div>
      </v-list>
    </v-menu>
    
    
  </v-app-bar>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import config from '../../config';
  import Search from '@/components/Search/Search';

  export default {
    name: 'Header',
    components: { Search },
    data() {
      return {
        config,
        user: JSON.parse(localStorage.getItem('user') || {}),
        notificationsBadge: true,
        messageBadge: true,
      };
    },
    computed: {
      ...mapState('layout', { drawer: (state) => state.drawer }),
      ...mapState('auth', {
        currentUser: (state) => state.currentUser,
      }),
      avatar() {
        return this.currentUser &&
          this.currentUser.avatar &&
          this.currentUser.avatar.length
          ? this.currentUser.avatar[0].publicUrl
          : null;
      },
      usernameFormatted() {
        if (this.currentUser && this.currentUser.username) {
          return this.currentUser.username.split(' ').map(word => {
            if (word) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
            return word;
          }).join(' ');
        }
        return 'User';
      },
      firstUserLetter() {
        return this.currentUser && this.currentUser.username
          ? this.currentUser.username[0].toUpperCase()
          : 'U';
      },
      DRAWER_STATE: {
        get() {
          return this.drawer;
        },
      },
    },
    watch: {
      DRAWER_STATE() {
        this.setMenuLocationStyle();
      }
    },
    methods: {
      setMenuLocationStyle() {
        this.$nextTick(() => {
          const div = document.querySelector('.v-menu__content');
          if (div) {
            div.classList.add('forced-position');
          }
        });
      },
      ...mapActions('layout', ['TOGGLE_DRAWER']),
      ...mapActions('auth', ['logoutUser']),
    },
  };
</script>

<style lang="scss" scoped>
  .main-header {
    ::v-deep .v-list {
      .v-list-item:hover {
        background-color: #f3f5ff;
        &:before {
          opacity: 0;
        }
      }
      .v-list-item:hover .v-list-item__icon {
        .v-icon {
          color: rgba(var(--v-primary-base), 0.8) !important;
        }
      }
      .link-item {
        cursor: pointer;
      }
    }
    .greeting-text {
      font-size: 14px;
      span {
        font-weight: 600;
      }
    }
  }
  .forced-position {
    top: calc(0% + 62px) !important;
    left: calc(100% - 200px) !important;
  }
</style>
