<template>
  <div>
    <v-row>
      <v-col cols="12" md='12' class="d-flex justify-center">
        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          :href="icon.link"
          class="mt-1"
          color="greyTint"
          icon>
          <v-icon size="22px">{{ icon.icon }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md='12' class="d-flex justify-center">
        <div class="p-0 m-0">
          <i>The power is in our algorithms.</i>
        </div>
      </v-col>
      <v-col cols="12" md='12' class="d-flex justify-center">
        <div class="primary--text">
          {{ new Date().getFullYear() }} &copy; Signal<i>View</i> - All rights reserved.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import config from '../../config';

  export default {
    name: 'Footer',
    data: () => ({
      config,
      icons: [
        {icon: 'mdi-facebook', link: 'https://www.facebook.com/'},
        {icon: 'mdi-instagram', link: 'https://www.instagram.com/'},
        {icon: 'mdi-twitter', link: 'https://x.com/'},
      ],
    })
  };
</script>
