<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  };
</script>

<style lang="scss">
  @import 'styles/base';

  @import '~vuetify/src/styles/main.sass';
</style>
