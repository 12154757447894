<template>
  <v-app>
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex login-background-image">
          <div class="d-flex">
            <v-img src="@/assets/logo.svg" contain></v-img>
            <p>Signal<i>View</i></p>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          class="login-part d-flex align-center justify-center"
        >
          <v-row no-gutters class="align-start">
            <v-col
              cols="12"
              class="login-part d-flex align-center justify-center flex-column"
            >
              <div class="login-wrapper">
                <v-tabs grow v-model="loginTabs" class="my-16" light>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab :href="`#tab-login`"> LOGIN </v-tab>

                  <v-tab-item :value="'tab-login'">
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col>
                            <p
                              class="
                                login-slogan
                                display-2
                                text-center
                                font-weight-medium
                                my-10
                              "
                            >
                              Welcome!
                            </p>
                          </v-col>
                          <v-form ref="log" v-model="valid" lazy-validation>
                            <v-col>
                              <v-text-field
                                light
                                id="username"
                                ref="username"
                                autocomplete="username" 
                                v-model="username"
                                :rules="usernameRules"
                                single-line
                                type="text"
                                label="Username"
                                required
                                @keydown.enter="isFormValid && login()"
                              ></v-text-field>
                              <v-text-field
                                light
                                id="current-password"
                                ref="current-password"
                                autocomplete="current-password" 
                                v-model="password"
                                :rules="passRules"
                                single-line
                                type="password"
                                label="Password"
                                required
                                @keydown.enter="isFormValid && login()"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-btn
                                class="text-capitalize"
                                large
                                block
                                :disabled="!isFormValid"
                                color="primary"
                                :loading="isFetching"
                                @click="login"
                              >
                                Sign In
                              </v-btn>
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">
                  {{ new Date().getFullYear() }} &copy; Signal<i>View</i> - All rights reserved.
                </div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="alert" color="error">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="alert = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'Login',
    data() {
      return {
        valid: true,
        loginTabs: '',
        username: '',
        usernameRules: [
          (v) => !!v || 'Username is required',
        ],
        password: '',
        passRules: [
          (v) => !!v || 'Password is required',
        ],
        alert: false,
        message: '',
      };
    },
    methods: {
      ...mapActions('register', ['registerUser', 'registerError']),
      ...mapActions('auth', ['loginUser', 'receiveToken', 'receiveLogin']),
      login() {
        const username = this.username;
        const password = this.password;
        this.loginUser({ username, password });
      }
    },
    computed: {
      ...mapState('auth', {
        isFetching: (state) => state.isFetching,
        errorMessage: (state) => state.errorMessage,
      }),
      isFormValid() {
        return this.password != null 
            && this.password.length !== 0 
            && this.username != null 
            && this.username.length !== 0;
      },
    },

    watch: {
      errorMessage() {
        this.message = this.errorMessage;
        this.alert = true;
      },
    },

    created() {
      const token = this.$route.query.token;
      if (token) {
        this.receiveToken(token);
      } else if (this.isAuthenticated()) {
        this.receiveLogin();
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/_variables.scss';

  .main-part {
    width: 100%;
    height: 100vh;
    background-color: var(--v-primary-base);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 84px;
        color: white;
        font-weight: 500;
      }
      .v-image {
        margin-bottom: 32px;
      }
    }
  }
  .login-part {
    width: 100%;
    height: 100vh;
    background-color: #f6f7ff;
    overflow-y: auto;
    .login-wrapper {
      width: 320px;
      height: auto;
      .login-slogan {
        color: #4a4a4a;
      }
      .v-btn.google {
        box-shadow: $card-shadow !important;
      }
      ::v-deep .v-tabs {
        .v-tab {
          font-size: 18px;
        }
        .v-item-group {
          background-color: #f6f7ff;
        }
      }
    }
    .v-footer {
      position: absolute;
      bottom: 0;
      background-color: #f6f7ff;
    }
  }
  .login-background-image {
    background: url("../../assets/img/login/login-background-image.jpg") no-repeat center center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.45);
  }
  @media (max-width: 1263px) {
    .login-part {
      height: 85vh;
    }
  }
</style>
