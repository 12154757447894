const hostApi = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'http://vps-cb7f06c9.vps.ovh.net';
const portApi = process.env.NODE_ENV === 'development' ? 8080 : 8080;
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}/patternScan/api`;

export default {
  light: {
    primary: '#2A3B8C',           /* Un azul oscuro, más elegante */
    primaryConst: '#2A3B8C',
    secondary: '#DA4167',         /* Un rosa fuerte, atractivo */
    secondaryConst: '#DA4167',
    error: '#E94E77',             /* Un rosa vibrante para errores */
    warning: '#FFC260',           /* Amarillo suave para advertencias */
    success: '#4CAF50',           /* Verde más claro y fresco para éxito */
    successConst: '#4CAF50',
    info: '#736CED',              /* Un púrpura calmante para información */
    greyBold: '#4A4A4A',          /* Gris oscuro para textos fuertes */
    greyBoldConst: '#4A4A4A',
    greyMedium: '#6E6E6E',        /* Gris medio para textos secundarios */
    greyTint: '#B9B9B9',          /* Un gris claro para bordes */
    default: '#F2F4F6',           /* Un fondo gris muy claro y limpio */
    iconColor: '#FFFFFF',         /* Blanco para iconos */
  },
  dark: {
    primary: '#536dfe',
    primaryConst: '#536dfe',
    secondary: '#FF5C93',
    secondaryConst: '#FF5C93',
    success: '#3CD4A0',
    successConst: '#3CD4A0',
    error: '#FF4081',
    greyMedium: '#bfbfbf',
    greyBold: '#d6d6d6',
    greyBoldConst: '#4a4a4a',
    greyTint: '#9b9b9b',
    default: '#e0e0e0',
  },
  hostApi,
  portApi,
  baseURLApi,
};
