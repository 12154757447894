import Vue from 'vue';
import Router from 'vue-router';

// Login y Home
import Login from '@/pages/Login/Login';
import Home from '@/pages/Home/Home';

// Pantalla de error por defecto cuando no existe una ruta
import Layout from '@/components/Layout/Layout';
import Error from '@/pages/Error/Error';

import { isAuthenticated } from './mixins/auth';

Vue.use(Router);

export default new Router({
  routes: [
    { path: '/', redirect: { name: 'Home' } },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/',
      name: 'Layout',
      component: Layout,
      redirect: { name: 'Layout' },
      beforeEnter: (to, from, next) => {
        isAuthenticated() ? next() : next({ path: '/login' });
      },
      children: [
        // Pantallas principales del proyecto
        // Es necesario estar autenticado para tener acceso a ellas
        {
          path: 'home',
          name: 'Home',
          component: Home,
        },
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: Error,
    },
  ],
});
